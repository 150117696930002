import * as firebase from "firebase/app";
import 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA6K0D5Bq_j1mBiQrJ46JBhN9ubyzS0sD4",
    authDomain: "calendar-event-a0e64.firebaseapp.com",
    databaseURL: "https://calendar-event-a0e64.firebaseio.com",
    projectId: "calendar-event-a0e64",
    storageBucket: "calendar-event-a0e64.appspot.com",
    messagingSenderId: "1027874166774",
    appId: "1:1027874166774:web:a6914fb110d97998b059d7",
    measurementId: "G-N6JT6VXHPX",
};

let messaging = null;
try {
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
} catch (err) {
    console.log(err);
}

export default messaging;