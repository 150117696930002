import { constants } from './Constants';
import { toastUtil } from './ToastUtil';

const getStorageObj = () => {
    return localStorage;
}

const updateOrCreateHeader = header => {
    if (header === null || header === void 0) {
        header = {
            method: 'GET',
            headers: {

            }
        }
    }
    let token = getStorageObj().getItem(constants.TOKEN_VARIABLE_NAME);
    if (token === null) {
    }
    if (header.headers === null || header.headers === void 0) {
        header.headers = {};
    }
    header.headers.Authorization = "Bearer " + token;
    return header;
}

const checkResponseErrorCode = (data, method) => {
    if (data && data.errorCode > 0) {
        if (data.errorCode === 401) {
            setTimeout(() => {
                toastUtil.showErrorMsg(constants.ERR_EXPIRED_TOKEN);
            }, 100);
            window.location = constants.HASH_LOGIN;
            tokenUtil.cllearAllSavedData();
        } else {
            if (data.message === constants.BIEU_MAU_KHONG_THE_XOA) {
                toastUtil.showWarningMsg(constants.NEW_MESSAGE_BIEU_MAU_KHONG_THE_XOA)
            }
            else {
                toastUtil.showErrorMsg(data.message)
            }
        }
    }
}

/**
 * To clear all saved data in client browser
 */
const cllearAllSavedData = () => {
    getStorageObj().clear();
}

export const tokenUtil = {
    updateOrCreateHeader,
    getStorageObj,
    cllearAllSavedData,
    checkResponseErrorCode,
};