import React from 'react';
import ReactDOM from 'react-dom';
import Toast from './Toast';
import { constants } from './Constants';

const showToastMssg = (msg) => {
    if (!msg) {
        msg = 'Success.';
    }
    ReactDOM.render(<Toast message={msg} />
        , document.getElementById('toastDivElm'));

}
const showDefaultMsg = (msg) => {
    if (msg === void 0) {
        msg = 'Notification.';
    }
    ReactDOM.render(<Toast message={msg} type={constants.TOAST_TYPE_DEFAULT} />
        , document.getElementById('toastDivElm'));

}
const showErrorMsg = (msg) => {
    if (msg === void 0) {
        msg = 'Error.';
    }
    console.log('render toasttt');
    ReactDOM.render(<Toast message={msg} type={constants.TOAST_TYPE_ERROR} duration={3000} />
        , document.getElementById('toastDivElm'));

}
const showSuccessMsg = (msg) => {
    if (msg === void 0) {
        msg = 'Success.';
    }
    ReactDOM.render(<Toast message={msg} type={constants.TOAST_TYPE_SUCCESS} />
        , document.getElementById('toastDivElm'));

}

const showWarningMsg = (msg) => {
    if (!msg) {
        msg = 'Warning.';
    }
    ReactDOM.render(<Toast message={msg} type={constants.TOAST_TYPE_WARNING} duration={5000} />
        , document.getElementById('toastDivElm'));
}

export const toastUtil = {
    showDefaultMsg,
    showToastMssg,
    showErrorMsg,
    showSuccessMsg,
    showWarningMsg,
}