const API_ROOT = process.env.REACT_APP_API_DOMAIN;

const USER_LOGIN = API_ROOT + 'api/user_account/login_for_web/';
const USER_REGISTER = API_ROOT + '';
const USER_PASSWORD = API_ROOT + 'api/user_account/password/';
const GET_USER_DETAILS = API_ROOT + 'api/user_account/profile/';
const USER_LOGOUT = API_ROOT + 'api/user_account/logout/';
const GET_ARTICLE_LIST = API_ROOT + 'api/article/list/';
const GET_USERS_LIST_MESSAGE = API_ROOT + 'api/message/list_user/';
const GET_MESSAGES_LIST = API_ROOT + 'api/message/get_message/';
const SEND_MESSAGE = API_ROOT + 'api/message/add/';
const GET_NOTIFICATION_LIST = API_ROOT + 'api/notification/get_notification_list/'
const ADD_NEW_ARTICLE = API_ROOT + 'api/article/create/';
const UPLOAD_FILE = API_ROOT + 'api/attachment/upload/';
const REMOVE_FILE = API_ROOT + 'api/attachment/delete/';
const CREATE_FORM = API_ROOT + 'api/event/create_form_storage/';
const ACTIVE_ACCOUNT = API_ROOT + 'api/user_account/active/';
const GET_USER_PROFILE = API_ROOT + 'api/user_account/get_fpts_user_profile/';
const EVENT_LIST = API_ROOT + 'api/event/list/';
const GET_ARTICLE_DETAILS = API_ROOT + 'api/article/details/';
const UPDATE_USER_PROFILE = API_ROOT + 'api/user_account/update_fpts_user_profile/';
const CREATE_EVENT = API_ROOT + 'api/event/create_event/';
const GET_ANNOUNCEMENT_DETAIL = API_ROOT + 'api/announcement/get_announcement_detail_for_web/'
const UPDATE_ARTICLE = API_ROOT + 'api/article/update/';
const GET_EVENT_DETAILS = API_ROOT + 'api/event/details/';
const UPDATE_EVENT = API_ROOT + 'api/event/update/';
const UPDATE_ANNOUNCEMENT_DETAIL = API_ROOT + 'api/announcement/update_announcement_detail_for_web/';
const GET_UNREAD_NOTIFICATION_NUM = API_ROOT + 'api/notification/get_unread_notification_num/';
const GET_FORM_LIST = API_ROOT + 'api/event/get_form_storage_list/';
const UPDATE_FORM = API_ROOT + 'api/event/update_form/';
const RESET_PASSWORD = API_ROOT + 'api/user_account/reset_password/';
const FORGOT_PASSWORD = API_ROOT + 'api/user_account/forgot_password/?email=';
const DELETE_FORM = API_ROOT + 'api/event/delete_form/';
const READ_ALL_NOTIFICATION = API_ROOT + 'api/notification/read_all_notifications/';
const GET_UNREAD_MESSAGES = API_ROOT + 'api/notification/get_unread_message_notification_num/';
const READ_ALL_MESSAGES = API_ROOT + 'api/notification/read_all_message_by_email/';

export const API_CONST = {
    USER_LOGIN,
    USER_REGISTER,
    USER_PASSWORD,
    GET_USER_DETAILS,
    USER_LOGOUT,
    GET_ARTICLE_LIST,
    GET_USERS_LIST_MESSAGE,
    GET_MESSAGES_LIST,
    SEND_MESSAGE,
    GET_NOTIFICATION_LIST,
    ADD_NEW_ARTICLE,
    UPLOAD_FILE,
    REMOVE_FILE,
    CREATE_FORM,
    ACTIVE_ACCOUNT,
    GET_USER_PROFILE,
    EVENT_LIST,
    GET_ARTICLE_DETAILS,
    UPDATE_USER_PROFILE,
    CREATE_EVENT,
    GET_ANNOUNCEMENT_DETAIL,
    UPDATE_ARTICLE,
    GET_EVENT_DETAILS,
    UPDATE_EVENT,
    GET_UNREAD_NOTIFICATION_NUM,
    GET_FORM_LIST,
    UPDATE_ANNOUNCEMENT_DETAIL,
    UPDATE_FORM,
    DELETE_FORM,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    READ_ALL_NOTIFICATION,
    GET_UNREAD_MESSAGES,
    READ_ALL_MESSAGES,
}