import React, { PureComponent } from 'react'

export default class LoadingPage extends PureComponent {
    render() {
        return (
            <div>

            </div>
        )
    }
}
