import { API_CONST } from "./API";
import fetch from 'cross-fetch';
import { tokenUtil } from '../utils/token';

const getUserProfile = async (page, page_size, full_name_filter, uid) => {
    let response;
    let options = {
        method: 'GET',
    }
    let url = API_CONST.GET_USER_PROFILE + '?page=' + page + '&page_size=' + page_size + (full_name_filter ? ('&full_name_filter=' + full_name_filter) : '') + (uid ? ('&event_uid=' + uid) : '');
    tokenUtil.updateOrCreateHeader(options);
    try {
        response = await fetch(url, options);
        let body = await response.json();
        tokenUtil.checkResponseErrorCode(body, options.method);
        return [body.errorCode === 0, body];
    }
    catch (e) {
        if (response && response.statusText) {
            return [false, response.statusText];
        } else {
            return [false, e.message];
        }
    }
};

const updateUserProfile = async (params) => {
    let response;
    let data = new FormData()
    data.append('email', params.email);
    data.append('staff_name', params.staff_name);
    data.append('status', params.status);
    data.append('fpt_customer', params.fpt_customer);
    let options = {
        method: 'POST',
        body: data,
    }
    let url = API_CONST.UPDATE_USER_PROFILE;
    tokenUtil.updateOrCreateHeader(options);
    try {
        response = await fetch(url, options);
        let body = await response.json();
        tokenUtil.checkResponseErrorCode(body, options.method);
        return [body.errorCode === 0, body];
    }
    catch (e) {
        if (response && response.statusText) {
            return [false, response.statusText];
        } else {
            return [false, e.message];
        }
    }
}

const activeAccount = async (userToken) => {
    let response;
    let options = {
        method: 'GET',
    }
    let url = API_CONST.ACTIVE_ACCOUNT + '?user_token=' + userToken;
    // tokenUtil.updateOrCreateHeader(options);
    try {
        response = await fetch(url, options);
        let body = await response.json();
        return [body.errorCode === 0, body];
    }
    catch (e) {
        if (response && response.statusText) {
            return [false, response.statusText];
        } else {
            return [false, e.message];
        }
    }
}

export const UserService = {
    getUserProfile,
    updateUserProfile,
    activeAccount,
}