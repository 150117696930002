import React, { PureComponent } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import Loadable from 'react-loadable';
import LoadingPage from './utils/LoadingPage';
import { constants } from './utils/Constants';
import messaging from './firebaseConfig';
import CommonContext from './Context/CommonContext';
import { notification } from 'antd';
import { UserService } from './services/UserServices';
import { toastUtil } from './utils/ToastUtil';

const DefaultLayout = Loadable({
  loader: () => import('./container/DefaultLayout'),
  loading: LoadingPage,
});

const Login = Loadable({
  loader: () => import('../src/views/Login/Login'),
  loading: LoadingPage,
});

const ActiveAccount = Loadable({
  loader: () => import('../src/views/ActiveAccount/ActiveAccount'),
  loading: LoadingPage,
});

const ForgotPassword = Loadable({
  loader: () => import('../src/views/ForgotPassword/ForgotPassword'),
  loading: LoadingPage,
});

const ResetPassword = Loadable({
  loader: () => import('../src/views/ResetPassword/ResetPassword'),
  loading: LoadingPage,
});

class App extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      deviceToken: null,
      isReloadNoti: false,
      isReloadUnread: false,
      isReloadUnreadMessages: false,
    }
  }

  onLoadMessage = (messaging) => {
    messaging.onMessage((payload) => {
      console.log('payload')
      const args = {
        message: payload.notification.title,
        description: payload.notification.body,
        className: 'custom-notification',
      };
      notification.open(args);
      this.handleReloadNoti();
    })
  }

  onRefreshToken = (messaging) => {
    // messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        console.log('Token refreshed.');
        if (refreshedToken) {
          localStorage.setItem(constants.DEVICE_TOKEN, refreshedToken);
          this.setState({
            deviceToken: refreshedToken,
          });
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
      });
    // });
  }

  reloadRequestNotification = () => {
    if (messaging) {
      console.log(Notification.permission)
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            messaging.getToken().then((deviceToken) => {
              console.log(deviceToken);
              if (deviceToken) {
                localStorage.setItem(constants.DEVICE_TOKEN, deviceToken);
                this.setState({
                  deviceToken: deviceToken,
                });
              } else {
                console.log('No Instance ID token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });

          } else {
            console.log('Unable to get permission to notify.');
          }
        });
        this.onRefreshToken(messaging);
        this.onLoadMessage(messaging)
      }
      else {
        this.onRefreshToken(messaging);
        this.onLoadMessage(messaging)
      }
    }
  }

  async componentDidMount() {
    this.reloadRequestNotification();
  }

  handleReloadNoti = () => {
    this.setState({
      isReloadNoti: !this.state.isReloadNoti
    })
  }

  handleReloadUnread = () => {
    this.setState({
      isReloadUnread: !this.state.isReloadUnread
    })
  }

  handleReloadUnreadMessages = () => {
    this.setState({
      isReloadUnreadMessages: !this.state.isReloadUnreadMessages
    })
  }

  render() {
    return (
      <HashRouter>
        <ScrollToTop>
          <CommonContext.Provider
            value={{
              deviceToken: this.state.deviceToken,
              isReloadNoti: this.state.isReloadNoti,
              isReloadUnread: this.state.isReloadUnread,
              isReloadUnreadMessages: this.state.isReloadUnreadMessages,
              handleReloadUnread: this.handleReloadUnread,
              handleReloadUnreadMessages: this.handleReloadUnreadMessages,
            }}
          >
            <Switch>
              <Route path={constants.ROUTE_LOGIN} exact name="Login" component={Login} />
              <Route path={constants.ROUTE_ACTIVE_ACCOUNT} name="Active account" component={ActiveAccount} />
              <Route path={constants.ROUTE_FORGOT} exact name="Forgot password" component={ForgotPassword} />
              <Route path={constants.ROUTE_RESET} exact name="Reset password" component={ResetPassword} />
              <Route path={constants.ROUTE_ROOT} name="Home" component={DefaultLayout} />
            </Switch>
          </CommonContext.Provider>
        </ScrollToTop>
      </HashRouter>
    )
  }
}

export default App;

