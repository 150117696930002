import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';
import { constants } from './Constants';

class Toast extends Component {

   actionMethod = (msg, type, duration) => {
      if (type === constants.TOAST_TYPE_DEFAULT) {
         return this.showInfoMsg(msg);
      } else if (type === constants.TOAST_TYPE_ERROR) {
         return this.showErrorMsg(msg, duration);
      } else if (type === constants.TOAST_TYPE_SUCCESS) {
         return this.showSuccessMsg(msg);
      } else if (type === constants.TOAST_TYPE_WARNING) {
         return this.showWarningMsg(msg, duration);
      } else {
         return this.showInfoMsg(msg);
      }
   }

   showDefaultMsg = (msg) => {
      toast(msg, {
         position: toast.POSITION.BOTTOM_LEFT,
         type: toast.TYPE.DEFAULT,
         autoClose: 3000,
         hideProgressBar: true,
         closeOnClick: false,
         pauseOnHover: true,
         draggable: true
      });
   }

   showErrorMsg = (msg, duration) => {
      toast(
         <div className="toast-content">
            <i className="ic-toast-error icon-16"></i>
            <label className="toast-content-msg">{msg}</label>
         </div>
         , {
            position: toast.POSITION.BOTTOM_LEFT,
            type: toast.TYPE.ERROR,
            autoClose: duration || false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-common',
         });
   }

   showWarningMsg = (msg, duration) => {
      toast(
         <div className="toast-content">
            <i className="ic-toast-warning icon-16"></i>
            <label className="toast-content-msg">{msg}</label>
         </div>
         , {
            position: toast.POSITION.BOTTOM_LEFT,
            type: toast.TYPE.WARNING,
            autoClose: duration || 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-common',
         });
   }

   showInfoMsg = (msg) => {
      toast(
         <div className="toast-content">
            <i className="ic-toast-success icon-16"></i>
            <label className="toast-content-msg">{msg}</label>
         </div>
         , {
            position: toast.POSITION.BOTTOM_LEFT,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-common',
         });
   }

   showSuccessMsg = (msg) => {
      toast(
         <div className="toast-content">
            <i className="ic-toast-success icon-default icon-16"></i>
            <label className="toast-content-msg">{msg}</label>
         </div>
         , {
            position: toast.POSITION.BOTTOM_LEFT,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-common',
         });
   }

   render() {
      const { message, type, duration } = this.props;
      return (
         <div>
            {this.actionMethod(message, type, duration)}
            <ToastContainer
               className="toast-container"
               closeButton={false}
               transition={Slide}
               newestOnTop={true}
            />
         </div>
      )
   }
}

export default Toast;
