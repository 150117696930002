const TOKEN_VARIABLE_NAME = 'CalendarEventToken2020';

const HASH_LOGIN = '#login';

const ROUTE_ROOT = '/';
const ROUTE_LOGIN = '/login';
const ROUTE_ACTIVE_ACCOUNT = '/active-account';
const ROUTE_FORGOT = '/forgot-password';
const ROUTE_RESET = '/reset-password';
const ROUTE_INFO = "/dashboard";
const ROUTE_FORM = "/form";
const ROUTE_NOTIFICATION = "/notification";
const ROUTE_USER = "/user";
const ROUTE_DISCUSS = "/discussion";
const ROUTE_CONTENT = "/content";
const ROUTE_EXTENDED = "/extend";
const ROUTE_ADD_INFORMATION = '/add-information';
const ROUTE_UPDATE_INFORMATION = '/updates-event';
const ROUTE_VIEW_INFORMATION = '/view-event';
const ROUTE_UPDATE_INFORMATION_DETAIL = '/updates-event/:event_uid';
const ROUTE_VIEW_INFORMATION_DETAIL = '/view-event/:event_uid';
const ROUTE_ADD_CONTENT = '/add-content';
const ROUTE_UPDATES_ARTICLE = '/updates-content';
const ROUTE_UPDATES_ARTICLE_DETAIL = '/updates-content/:article_uid';
const ROUTE_VIEW_ARTICLE = '/view-content';
const ROUTE_VIEW_ARTICLE_DETAIL = '/view-content/:article_uid';

const TEXT = 'TEXT';
const TEXTAREA = 'TEXTAREA';
const SINGLE_SELECT = 'SINGLE_SELECT';
const MULTIPLE_SELECT = 'MULTIPLE_SELECT';
const MULTIPLE_SELECT_MODE_TAGS = 'MULTIPLE_SELECT_MODE_TAGS';
const NUMBER = 'NUMBER';
const RADIO = 'RADIO';
const DATE = 'DATE';
const TIME = 'TIME';
const FILE = 'FILE';
const CHECKBOX = 'CHECKBOX';
const ADD_FORM = 'ADD_FORM';
const TEXT_EDITOR = 'TEXT_EDITOR';

const INFO = "dashboard";
const FORM = "form";
const NOTIFICATION = "notification";
const USER = "user";
const DISCUSS = "discussion";
const CONTENT = "content";
const EXTENDED = "extend";

const TOAST_TYPE_DEFAULT = 'TOAST_TYPE_DEFAULT'
const TOAST_TYPE_ERROR = 'TOAST_TYPE_ERROR'
const TOAST_TYPE_SUCCESS = 'TOAST_TYPE_SUCCESS'
const TOAST_TYPE_WARNING = 'TOAST_TYPE_WARNING'
const TOAST_TYPE_INFO = 'TOAST_TYPE_INFO'

const ERR_EXPIRED_TOKEN = "Phiên làm việc đã hết hạn";
const BIEU_MAU_KHONG_THE_XOA = 'Biểu mẫu không thể xoá';
const NEW_MESSAGE_BIEU_MAU_KHONG_THE_XOA = 'Biểu mẫu đang được sử dụng ở một thông tin sự kiện khác. Hãy vui lòng gỡ bỏ khỏi sự kiện để có thể xoá biểu mẫu.';
const DEVICE_TOKEN = 'DeviceTokenWebEZDiscloser';

const STATUS_AVAILABLE = 'A';
const STATUS_DRAFT = 'D';
const STATUS_WAITING = 'W';

export const constants = {
    TOKEN_VARIABLE_NAME,
    HASH_LOGIN,
    ROUTE_ROOT,
    ROUTE_LOGIN,
    ROUTE_ACTIVE_ACCOUNT,
    ROUTE_FORGOT,
    ROUTE_RESET,
    ROUTE_INFO,
    ROUTE_FORM,
    ROUTE_NOTIFICATION,
    ROUTE_USER,
    ROUTE_DISCUSS,
    ROUTE_CONTENT,
    ROUTE_EXTENDED,
    ROUTE_ADD_INFORMATION,
    ROUTE_UPDATE_INFORMATION,
    ROUTE_UPDATE_INFORMATION_DETAIL,
    ROUTE_VIEW_INFORMATION,
    ROUTE_VIEW_INFORMATION_DETAIL,
    ROUTE_ADD_CONTENT,
    ROUTE_UPDATES_ARTICLE,
    ROUTE_UPDATES_ARTICLE_DETAIL,
    ROUTE_VIEW_ARTICLE,
    ROUTE_VIEW_ARTICLE_DETAIL,
    TEXT,
    TEXTAREA,
    SINGLE_SELECT,
    MULTIPLE_SELECT,
    MULTIPLE_SELECT_MODE_TAGS,
    NUMBER,
    RADIO,
    DATE,
    TIME,
    FILE,
    CHECKBOX,
    ADD_FORM,
    TEXT_EDITOR,
    INFO,
    FORM,
    NOTIFICATION,
    USER,
    DISCUSS,
    CONTENT,
    EXTENDED,
    TOAST_TYPE_DEFAULT,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    TOAST_TYPE_WARNING,
    TOAST_TYPE_INFO,
    ERR_EXPIRED_TOKEN,
    BIEU_MAU_KHONG_THE_XOA,
    NEW_MESSAGE_BIEU_MAU_KHONG_THE_XOA,
    DEVICE_TOKEN,
    STATUS_AVAILABLE,
    STATUS_DRAFT,
    STATUS_WAITING,
}